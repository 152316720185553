import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/resuable/layout/layout';
import AuthorHeader from '../../components/page/blog-components/blog-author-page/author-header/author-header';
import AuthorBody from '../../components/page/blog-components/blog-author-page/author-body/author-body';
import Joinus from '../../components/resuable/joinus/joinus';
import useWindowSize from '../../components/hooks/useWindowSize';

const AuthorTemplate = ({ data }) => {
  const { name, description, avatar_urls, mpp_avatar, acf } = data.wordpressWpUsers;
  const { posts } = data.allWordpressPost;
  const { resources } = data.allWordpressWpA2IResources;
  const size = useWindowSize();
  return (
    <Layout
      isBlogMenuActive={size.width > 768}
    >
      <AuthorHeader
        name={name}
        avatar_urls={avatar_urls}
        mpp_avatar={mpp_avatar}
        acf={acf}
      />
      <AuthorBody 
        description={description}
        acf={acf}
        name={name}
        posts={posts}
        resources={resources}
      />
      <Joinus />
    </Layout>
  )
}

export default AuthorTemplate

export const query = graphql`
  query authorQuery($slug: String!) {
    wordpressWpUsers(slug: {eq: $slug}) {
      id
      name
      slug
      description
      avatar_urls {
        wordpress_96
      }
      mpp_avatar {
        wordpress_96
      }
      acf {
        cover_picture {
          source_url
          alt_text
          localFile {
            relativePath
            childImageSharp {
              fixed(fit: COVER, height: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        social_links {
          facebook_url
          twitter_url
        }
      }
    }
    allWordpressPost(filter: {author: {slug: {eq: $slug}}}, limit: 9) {
      posts: nodes {
        id
        title
        slug
        content
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        author {
          name
          avatar_urls {
            wordpress_96
          }
        }
      }
    }
    allWordpressWpA2IResources(limit: 6) {
      resources: nodes {
        id
        title
        slug
        featured_media {
          source_url
        }
        author {
          name
          avatar_urls {
            wordpress_96
          }
        }
      }
    }
  }
`

