import React from 'react'
import styled from 'styled-components'
import Section from '../../../../resuable/section/section'
import BlogCard from '../../../../resuable/blog/blog-card/blog-card'

import Facebook from '../../../../../assets/social/Facebook.inline.svg'
import Twitter from '../../../../../assets/social/Twitter.inline.svg'

const StyledAuthorBody = styled.div`
  width: 100%;
  div.container__author--info {
    width: 100%;
    margin: 20px 0px 60px;
    display: flex;
    div.container__sidebar {
      flex: 30%;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      h4 {
        margin-bottom: 10px;
        font-size: 20px;
      }
      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #fff;
        text-decoration: none;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          display: inline-block;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
    div.container__main {
      flex: 70%;
      padding-left: 20px;
      h4 {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }
  }
  div.container__author--posts {
    width: 100%;
    margin: 60px 0px;
    h2 {
      font-size: 28px;
    }
    div.posts {
      width: 100%;
      padding: 2rem 0 6rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    div.container__author--info {
      margin: 40px 0px 60px;
      flex-direction: column;
      div.container__sidebar {
        order: 2;
        flex: 100%;
        padding-right: 0px;
      }
      div.container__main {
        order: 1;
        flex: 100%;
        padding-left: 0px;
        padding-bottom: 20px;
      }
    }
    div.container__author--posts {
      margin: 20px 0 40px;
      h2 {
        margin-bottom: 0px;
        font-size: 20px;
      }
    }
  }
`

const AuthorBody = ({
  content,
  description,
  posts,
  resources,
  acf,
  name
}) => {
  return (
    <Section>
      <StyledAuthorBody>
        <div className="container__author--info">
          <div className="container__sidebar">
            <h4>Social</h4>
            {
              acf !== undefined && <a href={acf.social_links.facebook_url} target="_blank" rel="noopener noreferrer"><Facebook />Facebook</a>
            }
            {
              acf !== undefined && <a href={acf.social_links.twitter_url} target="_blank" rel="noopener noreferrer"><Twitter />Twitter</a>
            }
          </div>
          <div className="container__main">
            <h4>Bio</h4>
            <p>{description}</p>
          </div>
        </div>
        <div className="container__author--posts">
          <h2>Latests Posts by {name}</h2>
          <div className="posts">
            {
              posts !== null ? (
                posts.map((post, index) => {
                  return (
                    <BlogCard
                      key={index}
                      title={post.title}
                      content={content}
                      slug={post.slug}
                      featured_media={post.featured_media}
                      categories={post.categories}
                      author={post.author}
                    />
                  )
                })
              ) : null
            }
          </div>
          <h2>Latest A2i Resources by {name}</h2>
          <div className="posts">
            {
              resources !== null ? (
                resources.map((post, index) => {
                  return (
                    <BlogCard
                      key={index}
                      title={post.title}
                      content={content}
                      slug={post.slug}
                      featured_media={post.featured_media}
                      categories={post.categories}
                      author={post.author}
                    />
                  )
                })
              ) : null
            }
          </div>
        </div>
      </StyledAuthorBody>
    </Section>
  )
}

AuthorBody.defaultProps = {
  acf: {
    social_links: {
      facebook_url: null,
      twitter_url: null
    }
  }
}

export default AuthorBody