import React from 'react'
import styled from 'styled-components'

import SEO from '../../../../resuable/seo/seo'
import { colors, dimensions } from '../../../../../utils/styles/styles'

const StyledAuthorHeader = styled.header`
  width: 100%;
  div.container__cover {
    position: relative;
    width: 100%;
    height: 450px;
    img.cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    div.container__cover-overlay {
      position: absolute;
      top: 0px; left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.45) 40%, rgba(0, 0, 0, 0.25) 100%);
      div.cover-content {
        width: 100%;
        max-width: ${dimensions.siteWidth};
        display: flex;
        align-items: flex-end;
        img.profile {
          position: relative;
          bottom: -30px;
          height: 160px;
          width: 160px;
          margin-right: 40px;
          border-radius: 50%;
          border: 4px solid ${colors.pri};
        }
        h1.name {
          text-decoration: none;
        }
      }
    }
  }
  @media (max-width: 768px) {
    div.container__cover {
      height: 300px;
      div.container__cover-overlay {
        div.cover-content {
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          img.profile {
            position: static;
            height: 120px;
            width: 120px;
            margin: 0px;
          }
          h1 {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`

const AuthorHeader = ({
  name,
  avatar_urls,
  mpp_avatar,
  acf
}) => {
  return (
    <React.Fragment>
      <SEO
        title={`Author | ${name}`}
      />
      <StyledAuthorHeader>
        <div className="container__cover">
          {
            acf.cover_picture !== null ? (
              <img
                className="cover"
                src={acf.cover_picture.source_url}
                alt={'a generic cover image'}
              />
            ) : (
                <img
                  className="cover"
                  src="https://source.unsplash.com/1280x720/?pattern"
                  alt={'a generic cover image'}
                />
              )
          }
          {/* <img src="https://source.unsplash.com/1280x720/?pattern" alt="cover"/> */}
          <div className="container__cover-overlay">
            <div className="cover-content">
              <img src={mpp_avatar.wordpress_96} alt="Profile" className="profile" />
              <h1 className="name">{name}</h1>
            </div>
          </div>
        </div>
      </StyledAuthorHeader>
    </React.Fragment>
  )
}

AuthorHeader.defaultProps = {

}

export default AuthorHeader